import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {IconReferences} from "../../assets/icons/_icon-references";

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
  }

  public registerIcons(): void {
    this.loadIcons(IconReferences);
  }

  private loadIcons(iconReferences: { [key: string]: string }) {
    Object.entries(iconReferences).forEach(([key, iconUrl]) => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(iconUrl));
    });
  }
}
