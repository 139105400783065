import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string) {
    //Facebook
    this.meta.updateTag({ name: 'og:title', content: title });
    //Twitter
    this.meta.updateTag({ name: 'twitter:title', content: title });
    //HTML
    this.title.setTitle(title);
  }

  updateUrl(url: string) {
    //Facebook
    this.meta.updateTag({ name: 'og:url', content: url });
    //Twitter
    this.meta.updateTag({ name: 'twitter:domain', content: url });
    this.meta.updateTag({ name: 'twitter:url', content: url });
  }

  updateDescription(desc: string) {
    //Facebook
    this.meta.updateTag({ name: 'og:description', content: desc });
    //Twitter
    this.meta.updateTag({ name: 'twitter:description', content: desc });
    //HTML
    this.meta.updateTag({ name: 'description', content: desc });
  }

}
