import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
}
  from '@angular/common/http';
import { catchError, Observable, Subject, switchMap, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import {GetRefreshTokenResponse, OauthService} from './oauth.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class Interceptor implements HttpInterceptor {
  authService: any;
  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private injector: Injector, private router: Router, private dialogRef: MatDialog) {}

  addAuthHeader(request: HttpRequest<any>) {
    let newRequest;
    if (request.url.startsWith(environment.apiGateway)) {
      newRequest = request.clone({
        setHeaders: {
          ['x-entergy-oauth-token']: localStorage.getItem('idToken') ?? '',
        }
      })
    } else {
      // Qualtrics content security policy
      newRequest = request.clone({
        setHeaders: {
          'Content-Security-Policy': "connect-src https://*.qualtrics.com; frame-src https://*.qualtrics.com; img-src https://*.qualtrics.com; script-src https://*.qualtrics.com"
        }
      })
    } 

    return newRequest ?? request;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.getTokenFromRefreshToken().pipe(
        tap((obj: GetRefreshTokenResponse) => {
          if (obj.idToken) {
            localStorage.setItem('idToken', obj.idToken);
          }
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next(null);
        }),
        catchError((): any => {
          this.refreshTokenInProgress = false;
          this.logout();
        }));
    }
  }

  logout() {
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('redirectUrl');
    this.dialogRef.closeAll();
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  handleResponseError(error: any, request?: any, next?: any): any {
    if (request.url.includes('vehicle-types-api') || request.url.includes('vehicle-types-generic-api')) {
      return
    }

    if (request.url.indexOf('feature-flags') !== -1) return
    console.log(error);
    if (error?.error?.error === 'Invalid Code') {
      this.logout();
    }
    // Business error
    if (error.status === 400) {
      // Show message
    }

    // Invalid token error
    else if (error.status === 401) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          } else {
            this.logout();
          }
        }));
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      // Logout
      this.logout();
    }

    // Server error
    else if (error.status === 500) {
      this.logout();
      // Show message
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
    }

    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.authService = this.injector.get(OauthService);

    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(catchError(error => {
      return this.handleResponseError(error, request, next);
    }));
  }
}
