import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {Router} from '@angular/router';

interface GetTokenResponse {
  idToken: string;
  accessToken: string;
  refreshToken: string;
}
export interface GetRefreshTokenResponse {
  idToken: string;
  accessToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class OauthService {

  constructor(private http: HttpClient, public router: Router) {
  }

  async getToken(code: string): Promise<GetTokenResponse> {
    let url = `${environment.apiGateway}/oauth/token?code=${code}`;
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      url += '&redirectUrl=' + redirectUrl;
    }
    return lastValueFrom(this.http.get<GetTokenResponse>(url));
  }

  getTokenFromRefreshToken(): Observable<GetRefreshTokenResponse> {
    const refreshToken = localStorage.getItem('refreshToken');
    let url = `${environment.apiGateway}/oauth/refreshToken?refreshToken=${refreshToken}`;
    return this.http.get<GetRefreshTokenResponse>(url);
  }
  async isAdmin() {
    let url = `${environment.apiGateway}/isAdmin`;
    return firstValueFrom(this.http.get(url));
  }
}
