import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'internal-version',
    loadChildren: () => import('./modules/internal-calculator/internal-calculator.module').then(m => m.InternalCalculatorModule),
    data: {
      title: 'eMobility | We Power Life',
      url: 'https://emobility.entergy.com/login'
    }
  },
  {
    path: 'fleet-calculator',
    loadChildren: () => import('./modules/calculator/calculator.module').then(m => m.CalculatorModule),
    data: {
      title: 'eMobility | We Power Life - Fleet calculator',
      description:'Compare the cost of a traditional versus all-electric fleet and get insights into your fleet electrification journey.',
      url: 'https://emobility.entergy.com/fleet-calculator'
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    data: {
      title: 'eMobility | We Power Life - Admin',
      url: 'https://emobility.entergy.com/admin'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    data: {
      title: 'eMobility | We Power Life',
      url: 'https://emobility.entergy.com/login'
    }
  },
  {
    path: 'test-suites',
    loadChildren: () => import('./modules/test-suites/test-suites.module').then(m => m.TestSuitesModule),
    data: {
      title: 'eMobility | We Power Life',
      url: 'https://emobility.entergy.com/test-suites'
    }
  },
  {
    path: '',
    loadChildren: () => import('./modules/homepage/homepage.module').then(m => m.HomepageModule),
    data: {
      title: 'eMobility | We Power Life',
      description:'Compare costs between traditional versus all-electric vehicles and see how to save for your home or business.',
      url: 'https://emobility.entergy.com'
    }
  },
  {
    path: 'residential',
    loadChildren: () => import('./modules/homepage/residential-homepage/residential-homepage.module').then(m => m.ResidentialHomepageModule),
    data: {
      title: 'eMobility | We Power Life - Residential Homepage',
      description:'Compare the cost of a traditional versus all-electric vehicle and see how to save.',
      url: 'https://emobility.entergy.com/residential'
    }
  },
  {
    path: 'business',
    loadChildren: () => import('./modules/homepage/business-homepage/business-homepage.module').then(m => m.BusinessHomepageModule),
    data: {
      title: 'eMobility | We Power Life - Business Homepage',
      description:'Compare the cost of a traditional versus all-electric vehicle and see how to save.',
      url: 'https://emobility.entergy.com/business'
    }
  },
  {
    path: 'residential-calculator',
    loadChildren: () => import('./modules/residential/residential.module').then(m => m.ResidentialModule),
    data: {
      title: 'eMobility | We Power Life - Residential Calculator',
      description:'Compare the cost of a traditional versus all-electric vehicle and see how to save.',
      url: 'https://emobility.entergy.com/residential-calculator'
    }
  },
  {
    path: 'podcast',
    loadChildren: () => import('./modules/podcast/podcast.module').then(m => m.PodcastModule),
    data: {
      title: 'eMobility | We Power Life - Podcasts',
      description:'Join us as we drive toward a more sustainable future, one episode at a time.',
      url: 'https://emobility.entergy.com/podcast'
    }
  },
  {
    path: 'guide-plugin',
    loadChildren: () => import('./modules/guide-plugin/guide-plugin.module').then(m => m.GuidePluginModule),
    data: {
      title: 'eMobility | We Power Life - Guide to plug-in EVs',
      description:'Guide to plug-in EVs.',
      url: 'https://emobility.entergy.com/guide-plugin'
    }
  },
  {
    path: 'guide-chargers',
    loadChildren: () => import('./modules/guide-chargers/guide-chargers.module').then(m => m.GuideChargersModule),
    data: {
      title: 'eMobility | We Power Life - Understanding EV chargers',
      description:'Plug and power: Understanding EV chargers.',
      url: 'https://emobility.entergy.com/guide-chargers'
    }
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
