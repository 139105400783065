import {Component, OnInit, Renderer2} from '@angular/core';
import {IconService} from "./services/icon.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {fromEvent, Subscription} from "rxjs";
import { Observable } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SEOService } from './services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'pocs';

  resizeObservable$: Observable<Event> | undefined;
  resizeSubscription$: Subscription | undefined;

  constructor(
    private window: Window,
    private renderer: Renderer2,
    private iconService: IconService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private _seoService: SEOService
  ) {

    this.iconService.registerIcons();
  }


  ngOnInit(): void {
    this.findDeviceType();
    this.setIosHeight();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$?.subscribe( evt => {
      this.findDeviceType();
      this.setIosHeight();
    })

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this._seoService.updateTitle(event['title']);
        this._seoService.updateUrl(event['url']);
        this._seoService.updateDescription(event['description'])
    });
  }

  setIosHeight(){
    //https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97
    this.renderer.setStyle(document.body, '--doc-height', `${this.window.innerHeight}px`);

  }

  findDeviceType(){
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if(isMobile){
      this.renderer.addClass(document.body, 'is-mobile');
    }else{
      this.renderer.removeClass(document.body, 'is-mobile');
    }

    if(isTablet){
      this.renderer.addClass(document.body, 'is-tablet');
    }else{
      this.renderer.removeClass(document.body, 'is-tablet');
    }
  }

  scrollToTop() {
    window.scroll(0,0);
  }


}
